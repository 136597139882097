// import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { NavBar } from '@/components/navbar';

export default function Dashboard() {
  // const { user, isAuthenticated, isLoading } = useAuth0();
  const { user, error, isLoading } = useUser();
  if (isLoading) {
    return <div>Loading account ...</div>;
  }
  if (error) {
    console.info(error);
    return <div>
      NOT AUTHORIZED
   </div>;
  }

  return <div>
    <NavBar/>
    <div className="my-4 text-center">
      <div className="text-3xl my-4">Order Management System</div>
      <div>{user.name}</div>
    </div>
  </div>;
}
